<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 기간 -->
          <c-datepicker
            name="month"
            type="month"
            label="기간"
            default="today"
            v-model="searchParam.month"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            type="user" 
            label="안전관리자" 
            name="chmDangerManageUserId" 
            v-model="searchParam.chmDangerManageUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            type="user" 
            label="관리자" 
            name="chmDangerUserId" 
            v-model="searchParam.chmDangerUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            type="user" 
            label="안전대리자" 
            name="chmDangerSafetyUserId" 
            v-model="searchParam.chmDangerSafetyUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :editable="editable"
            :comboItems="completeFlagItems"
            itemText="codeName"
            itemValue="code"
            label="진행상태"
            name="chmDangerCompleteFlag"
            v-model="searchParam.chmDangerCompleteFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="위험물 취급일지 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="chmDangerJournalId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="등록" v-if="editable" icon="add" @btnClicked="onItemClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'chmDangerQuanSum'">
            <span v-html="getchmDangerQuanSum(props.row['chmDangerQuanSum'])" />
          </template>
        </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "danger-journal",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerDate",
            field: "chmDangerDate",
            label: "기간",
            style: 'width:180px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerTitle",
            field: "chmDangerTitle",
            label: "취급일지명",
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "chmDangerArea",
            field: "chmDangerArea",
            label: "설치장소",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "chmDangerManageUserName",
            field: "chmDangerManageUserName",
            label: "안전관리자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerMstName",
            field: "chmDangerMstName",
            label: "품명",
            style: 'width:300px',
            align: "left",
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: "chmDangerCompleteFlagName",
            field: "chmDangerCompleteFlagName",
            style: 'width:100px',
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerQuanSum",
            field: "chmDangerQuanSum",
            style: 'width:150px',
            label: "지정수량배수",
            align: "center",
            type: "custom",
            sortable: true,
          },
          // {
          //   name: "chmDangerUserName",
          //   field: "chmDangerUserName",
          //   style: 'width:200px',
          //   label: "관리자",
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   name: "chmDangerSafetyUserName",
          //   field: "chmDangerSafetyUserName",
          //   style: 'width:200px',
          //   label: "안전대리자",
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   name: "chmDangerCount",
          //   field: "chmDangerCount",
          //   style: 'width:250px',
          //   label: "지정수량",
          //   align: "left",
          //   sortable: true,
          // },
          // {
          //   name: "chmDangerMainUse",
          //   field: "chmDangerMainUse",
          //   style: 'width:150px',
          //   label: "주용도",
          //   align: "left",
          //   sortable: true,
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        chmDangerManageUserId: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerCompleteFlag: null,
        month: null,
      },
      period: [],
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      completeFlagItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '완료' },
      ],
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.chm.danger.journal.list.url;
      // url setting
      // code setting
      // this.searchParam.month = this.$comm.getThisMonth();
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "위험물 취급일지 상세"; // 위험물 취급일지 상세
      this.popupOptions.param = {
        chmDangerJournalId: row ? row.chmDangerJournalId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./dangerJournalDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getchmDangerQuanSum(_val) {
      let addTxt = '';
      if (_val === null) {
        _val = '',
        addTxt = '';
      } else if (_val > 1) {
        addTxt = ' <div class="quansumcls quansumOver blinking">초과</div>';
      } else if (_val < 1) {
        addTxt = ' <div class="quansumcls quansumDown">미만</div>';
      } else if (_val == 1) {
        addTxt = ' <div class="quansumcls quansumEqual">FULL</div>';
      }
      return _val + addTxt;
    }
  },
};
</script>

<style lang="sass">
  .quansumcls
    width: 40px
    height: 20px
    border-radius: 5px
    color: #fff
    font-size: 12px
    font-weight: 600
    display: inline-block
  .quansumOver
    background-color: red
  .quansumDown
    background-color: gray
  .quansumEqual
    background-color: blue
</style>
